@import '../../../../styles/base/variables.scss';

.modal {
  display: flex;
  margin: auto;
  width: 40vw;
  min-width: 350px;
  max-width: 430px;

  .header, .body, .footer {

    border: 0 none;
  }

  .body, .footer {
    display: flex;
    padding-top: 0;
  }

  .header {
    padding-bottom: 0;
    h3 {
      color: $black;
    }
  }

  .body {
    div[class*='form-group'] {
      margin: 0;
    }

    .formFieldContainer {
      display: inline-flex;
      margin: 15px 0 0 0;

      .checkboxLabel {
        margin: 0 15px;
        font-weight: bold;
      }

      .images {
        alt: '';
        width: 20px;
        height: 20px;
      }
    }

    .hoursContainer {
      display: inline-flex;
      justify-content: center;
      vertical-align: middle;
      width: 100%;
      text-align: center;

      input {
        height: 36px;
        min-height: 36px;
        border-radius: 5px;
      }
    }

    .buttonContainer {
      justify-content: center;
      width: 100%;
      display: flex;
      margin-top: 10px;
    }
  }
}