@import '../../styles/base/variables.scss';

.barContainer {
  width: 100%;
  height: 20px;

  @media (max-width: $screen-sm-min) {
    height: 17px;
  }

  @media (max-width: $screen-xs-min) {
    height: 14px;
  }

  .fill,
  .track {
    display: inline-block;
    height: 100%;
  }

  .fill {
    background-color: $jopsy-red;
  }

  .track {
    background-color: $backgroundDarker;
  }
}