@import '../../../styles/base/variables.scss';

.strengthsScreenContainer {
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  .upperListCardWrapper {
    width: calc(0.35 * (100vh - (#{$header-height} + #{$footer-height} + 51px)));
    margin: auto;
  }

  .lowerListCardWrapper {
    width: calc(0.2 * (100vh - (#{$header-height} + #{$footer-height} + 51px)));
    margin: auto;
  }

  .buttonContainer {
    width: 100%;
    text-align: center;
    padding: 10px 0 5px 0;
  }
}
