/* stylelint-disable no-descending-specificity */
@import './variables.scss';

form {
  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  .bg-danger {
    margin-top: 2px;
    padding: 5px;
  }

  .validationError {
    display: block;
    margin-top: 5px;
    padding: 0;
    font-size: 16px;
    color: $jopsy-red;
    background: none;
    //text-align-last: center;
  }

  .validationErrorHorizontal {
    float: right;
    padding-right: 15px;
    margin-bottom: -15px;
    margin-top: 2px;
    font-size: 12px;
    color: $jopsy-red;
    background: none;
    text-align: right;
  }
}

.form-control {
  display: inline-block;
  width: auto;
  min-height: 38px;
  border-radius: 0;
}

.form-control,
.form-control:hover,
.form-control:active,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

.field-array {
  padding-left: 0;
  list-style-type: none;

  li {
    margin-bottom: 15px;
  }
}

.default-form {
  label {
    display: inline-block;
    min-width: 180px;
    margin-right: 10px;
    color: $jopsy-red;
    font-weight: bold;
    font-size: 20px;
    text-align: right;
  }

  select,
  textarea,
  input,
  input:hover,
  input:active,
  input:focus {
    max-width: 80vw;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    color: $black;
    font-size: 20px;
    background: white;
    border: 1px solid $black;
    box-shadow: none;
    font-weight: normal;
  }

  .required {
    display: block;
    position: absolute;
    right: -10px;
    top: 0;

    @media (max-width: $screen-sm-min) {
      display: inline;
      position: relative;
    }
  }

  textarea {
    height: 150px !important;
    max-width: 100%;
  }

  button,
  button:focus,
  button:active {
    margin-top: 20px;
  }

  .form-group {
    height: auto;
    margin-top: 10px;
  }
}

.checkbox {
  min-height: 25px !important;
  margin: 0 !important;

  label {
    position: relative;
    padding: 0;

    span {
      display: inline-block;
      margin: 0 0 0 40px !important;
    }

    input,
    input:hover,
    input:active,
    input:focus {
      top: calc(50% - 12.5px);
      margin: 0 !important;
      appearance: none;
      height: 25px !important;
      width: 25px !important;
      background-color: $white;
      border: 1px solid $jopsy-red-dark !important;
      outline: none;
    }

    input:disabled {
      border-color: #94001a80 !important;
    }

    input:checked::after {
      content: '';
      padding: 2px;
      position: absolute;
      width: 9px;
      height: 18px;
      border: solid $jopsy-red;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      top: 1px;
      left: 7px;
    }

    input:checked:disabled::after {
      border-color: #94001a80; // $jopsy-red-dark + transparency
    }
  }
}

/* Customize the label (the container) */
.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  label {
    padding: 0;
  }

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .customRadioButton {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $grey;
    border-radius: 50%;
  }

  .customRadioButton:hover {
    background-color: $grey;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .customRadioButton::after {
    content: '';
    position: absolute;
    display: none;

    /* Style the indicator (dot/circle) */
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $white;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .customRadioButton {
    background-color: $jopsy-red;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .customRadioButton::after {
    display: block;
  }
}
