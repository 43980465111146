@import '../../styles/base/variables.scss';

$desktop-button-transition-time: 125ms;
$desktop-button-grow-margin: 10px;

.interestsTestScreenContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .interestsTestScreenContainerInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $backgroundDarker;
  }
}

.interestCarousel {
  max-width: 70vh;
  width: 100%;
  height: 100%;
  margin: auto;

  .carouselHeader {
    width: 100%;
    height: $interests-test-header-height;
    background-color: $white;

    .progressTopBar {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: calc(100% - 17px);
      height: 100%;
      padding: 17px 0 0 15px;

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -7px;
        padding: 5px;

        .backButton {
          height: calc(#{$header-height} / 3);
        }
      }

      .iconContainer:hover {
        cursor: pointer;
      }

      .progressContainer {
        width: 100%;

        .progressBar {
          position: relative;
          width: 200px;
          max-width: 50%;
          height: 5px;

          .progressFill {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }

          .progressBackground {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: $backgroundDarker;
            transition: left 0.5s;
          }
        }

        .progressLabel {
          margin-top: 5px;
          color: $jopsy-red;
          font-size: 12px;
        }
      }
    }
  }

  .carouselSlides {
    width: 100%;
    height: calc(100% - #{$interests-test-header-height});

    > div {
      width: 100%;
      height: 100%;

      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.interestCard {
  position: relative;
  width: 100%;
  height: 100%;

  .interestImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imageHint {
    -moz-animation: moveImage 0.5s linear;
    -webkit-animation: moveImage 0.5s linear;
    animation: moveImage 0.5s linear;
  }

  @keyframes moveImage {
    0% { transform: translateX(0); }
    50% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }

  .sliderContainer {
    position: absolute;
    height: $interests-test-slider-container-height;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    text-align: center;
    z-index: 1;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .titleWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc((#{$interests-test-slider-container-height} * 0.38));
      padding-top: calc((#{$interests-test-slider-container-height} * 0.05));
      font-size: 20px;

      @media (max-width: $screen-sm-min){
        font-size: 19px;
      }

      @media (max-width: $screen-xs-min){
        font-size: 18px;
      }
    }

    .sliderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 30px);
      height: calc((#{$interests-test-slider-container-height} * 0.28));
      max-width: 85%;
      margin: auto;

      .thumbsDownUp {
        width: 40px;
        height: 40px;
        object-fit: contain;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @media (max-width: $screen-sm-min){
          width: 35px;
          height: 35px;
        }

        @media (max-width: $screen-xs-min){
          width: 30px;
          height: 30px;
        }
      }

      .sliderInner {
        width: calc(100% - 80px);
        padding: 0 25px;

        @media (max-width: $screen-sm-min){
          padding: 0 20px;
        }
  
        @media (max-width: $screen-xs-min){
          padding: 0 15px;
        }
  
        .thumb {
          position: relative;
          width: 40px;
          height: 40px;
          margin: 0;
          border: none;
          outline: none;
          box-shadow: none;
          background: none;

          @media (max-width: $screen-sm-min){
            width: 35px;
            height: 35px;
          }

          @media (max-width: $screen-xs-min){
            width: 30px;
            height: 30px;
          }

          img {
            width: 100%;
            height: 100%;
          }

          .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }

          .sliderHint {
            -moz-animation: moveKnob 0.75s linear;
            -webkit-animation: moveKnob 0.75s linear;
            animation: moveKnob 0.75s linear;
          }

          @keyframes moveKnob {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(0); }
            75% { transform: translateX(10px); }
            100% { transform: translateX(0); }
          }
        }

        [class*=rc-slider-rail],
        [class*=rc-slider-track] {
          background-color: $backgroundDarker;
        }
      }
    }

    .additionalTextWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc((#{$interests-test-slider-container-height} * 0.3));
      font-size: 16px;

      @media (max-width: $screen-sm-min){
        font-size: 15px;
      }

      @media (max-width: $screen-xs-min){
        font-size: 14px;
      }
    }
  }
}

.subitCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $jopsy-red;

  .statusTextWrapper {
    max-width: 600px;
    padding: 15px;
    margin-bottom: 15px;
    color: $white;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .cancelTestButton {
    position: absolute;
    bottom: 30px;
  }
}

.fadedOut {
  opacity: 0;
  transition: opacity 1s;
}

.fadedIn {
  opacity: 1;
  transition: opacity 1s;
}

.previousSlideButtonWrapper,
.nextSlideButtonWrapper {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 0;
  top: calc(50% - (#{$interests-test-slider-container-height} / 2));
  transform: translateY(-50%);
  transition: all 250ms ease;
  transition-property: width, height, right;
  z-index: 1;
}

.previousSlideButton,
.previousSlideButton:active,
.previousSlideButton:focus,
.nextSlideButton,
.nextSlideButton:active,
.nextSlideButton:focus {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $white;
    color: $jopsy-red;

    svg {
      width: 55%;
      height: 55%;
      max-width: 80px;
      max-height: 80px;
    }
  }
}

.previousSlideButton:hover,
.nextSlideButton:hover {
  cursor: pointer;
}

.previousSlideButton:disabled,
.nextSlideButton:disabled {
  opacity: 0.5;
}

.previousSlideButton:disabled:hover,
.nextSlideButton:disabled:hover {
  cursor: not-allowed;
}

.previousSlideButtonWrapper {
  left: 10px;
}

.nextSlideButtonWrapper {
  right: 10px;
}

.nextSlideButtonWrapper.largeButton {
  width: 60px;
  height: 60px;
  right: 5px;
}

@media(min-width: 80vh) {
  .previousSlideButtonWrapper,
  .nextSlideButtonWrapper {
    flex: 1;
    position: relative;
    height: 100%;
    padding: 0;
    top: unset;
    left: unset;
    right: unset;
    transform: unset;
    transition: all 250ms ease;
    transition-property: width, height, right;
    margin-right: 0;
  }

  .previousSlideButton,
  .previousSlideButton:active,
  .previousSlideButton:focus,
  .nextSlideButton,
  .nextSlideButton:active,
  .nextSlideButton:focus {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    transition: all $desktop-button-transition-time ease;
    transition-property: width, margin-right, margin-left;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: $backgroundDarker;
      color: $textLight;
      transition: all $desktop-button-transition-time ease;
      transition-property: background-color, color, padding-right, padding-left;
  
      svg {
        width: 55%;
        height: 55%;
        max-width: 80px;
        max-height: 80px;
      }
    }
  }

  .previousSlideButton:hover,
  .nextSlideButton:hover {
    cursor: pointer;
  }
  
  .previousSlideButton:hover:enabled,
  .nextSlideButton:hover:enabled {
    .iconWrapper {
      background-color: $desktop-nav-button-hover-bg;
      color: $desktop-nav-button-hover-text;
    }
  }

  .previousSlideButton:hover:enabled {
    margin-right: -$desktop-button-grow-margin;
    width: calc(100% + #{$desktop-button-grow-margin});

    .iconWrapper {
      padding-right: $desktop-button-grow-margin;
    }
  }

  .nextSlideButton:hover:enabled {
    margin-left: -$desktop-button-grow-margin;
    width: calc(100% + #{$desktop-button-grow-margin});

    .iconWrapper {
      padding-left: $desktop-button-grow-margin;
    }
  }

  .previousSlideButton:disabled,
  .nextSlideButton:disabled {
    opacity: 1.0;

    .iconWrapper {
      color: $desktop-nav-button-disabled-text;
    }
  }

  .previousSlideButton:disabled:hover,
  .nextSlideButton:disabled:hover {
    cursor: not-allowed;
  }

  .nextSlideButtonWrapper.largeButton {
    width: unset;
    height: 100%;
    right: unset;
  }
}
