@import '../../../../styles/base/variables.scss';

.modal {
  display: flex;
  margin: auto;
  width: 70vw;
  min-width: 350px;
  max-width: 430px;

  .header, .body, .footer {
    border: 0 none;
  }

  .header {
    display: flex;
    margin: auto;
    padding-bottom: 0;
    h3 {
      color: $black;
    }
  }

  .footer {
    display: flex;
    padding-top: 0;
  }

  .body {
    display: inline-block;
    padding: 10px 6% 20px;

    .info {
      text-align: center;
    }

    .background {
      background-color: $background;

      .list {
        display: grid;
        grid-template-columns: 42% 16% 42%;
        column-gap: 0;
        font-weight: bold;
        margin-top: 30px;
        padding-bottom: 8px;
        width: 100%;

        .listHeaderItem {
          color: $jopsy-red;
          background-color: white;
          padding-bottom: 15px;
        }
        
      }
    }
    .buttonContainer {
      display: flex;
      margin: auto;
      width: 40%;
      margin-top: 10px;
    }
  }
}

.icon {
  alt: '';
  width: 20px;
  height: 20px;
  margin: auto;
}

.images {
  margin: auto;
  alt: '';
  width: 8vw;
  height: 8vw;
  max-width: 20px;
  max-height: 20px;
}

.images:hover {
  cursor: pointer;
}

.hoursContainer {
  margin: auto;
  display: flex;
}

@media(max-width: $screen-sm-min) {
  .images{
    margin-left: 10%;
  }
}