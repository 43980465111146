@import '../../../styles/base/variables.scss';

.card {
  background-color: $white;
  border-radius: 20px;
  width: 65%;
  margin: 40px auto;
  padding: 8% 5% 6% 5%;

  h2 {
    width: 100%;
    height: 50px;
    color: $black;
    margin-bottom: 15%;
    font-weight: normal;
    text-align: center;
  }

  .noButton {
    border: 0px solid !important;
  }

  .noButton:hover {
    background-color: unset !important;
  }

  button {
    width: 25%;
    padding: 0 5% !important;

    img {
      width: 100%;
      height: auto;
    }
  }
}
