@import '../../styles/base/variables.scss';

.footerContainer {
  display: flex;
  width: 100%;
  height: $footer-height;
  background-color: $jopsy-red;

  @media (max-width: $screen-sm-min) {
    height: $footer-height-sm;
  }

  @media (max-width: $screen-xs-min) {
    height: $footer-height-xs;
  }
}

.buttonBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.sideButtonsWrapper {
  display: flex;
  justify-content: space-between;
  width: 17%
}

.sideButtonsWrapper.left {
  margin-left: 5%;
}

.sideButtonsWrapper.right {
  margin-right: 5%;
}

.homeButton,
.homeButton:hover,
.homeButton:active,
.homeButton:focus {
  width: 100%;
  height: 100%;
  cursor: pointer;    

  .homeIcon {
    max-height: 30%;
  }
}

.restartButton,
.restartButton:hover,
.restartButton:active,
.restartButton:focus {   
  width: 50%;
  height: 100%;
  cursor: pointer;

  .restartIcon {
    max-height: 65%;
  }
}

.shareButton,
.shareButton:hover,
.shareButton:active,
.shareButton:focus { 
  width: 50%;
  height: 100%;
  cursor: pointer;
  
  .shareIcon {
    max-height: 35%;
  }
}

.optionsButton,
.optionsButton:hover,
.optionsButton:active,
.optionsButton:focus { 
  width: 50%;
  height: 100%;
  cursor: pointer;
  
  .optionsIcon {
    max-height: 30%;
  }
}

.optionsButton.fullWidth,
.optionsButton.fullWidth:hover,
.optionsButton.fullWidth:active,
.optionsButton.fullWidth:focus { 
  width: 100%;
}

.settingsMenuContainer {
  display: block;
  position: absolute;
  right: calc(4 * #{$max-content-width} / 100 + ((100vw - #{$max-content-width})) / 2);
  bottom: 125px;
  max-width: 500px;

  @media (max-width: $max-content-width) {
    right: calc(8vw - 30px);
    bottom: 125px;
  }
  
  @media (max-width: $screen-xs-min) {
    right: 5vw;
    bottom: 125px;
  }
  
  @media (max-width: 400px) {
    right: 4vw;
    bottom: 125px;
  }
  
  @media (max-width: 350px) {
    right: 3vw;
    bottom: 125px;
  }
  
  @media (max-width: 300px) {
    right: 5px;
    bottom: 125px;
  }
}