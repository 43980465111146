@import '../../../../styles/base/variables.scss';

$imageLength: 300px;
$imagePadding: 20px;

.strengthCardsList {
  position: relative;
  width: 100%;

  > div {
    overflow: visible !important;
  }

  .cardSlide {
    overflow: visible !important;
  }
}

.previousSlideButton,
.previousSlideButton:active,
.previousSlideButton:focus,
.nextSlideButton,
.nextSlideButton:active,
.nextSlideButton:focus {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  transform: translateY(-50%);

  img {
    width: 50px;
    height: 50px;

    @media (max-width: $screen-sm-min) {
      width: 43px;
      height: 43px;
    }
  
    @media (max-width: $screen-xs-min) {
      width: 36px;
      height: 36px;
    }
  }
}

.previousSlideButton:hover,
.nextSlideButton:hover {
  cursor: pointer;
}

.previousSlideButton:disabled,
.nextSlideButton:disabled {
  opacity: 0.5;
}

.previousSlideButton:disabled:hover,
.nextSlideButton:disabled:hover {
  cursor: not-allowed;
}

.previousSlideButton {
  left: 5px;
}

.nextSlideButton {
  right: 5px;
}
