@import '../../../styles/base/variables.scss';

.loginForm {
  .links {
    margin-top: 10px;
    font-weight: bold;
  }

  button {
    margin: 10px auto 0 auto !important;
  }
}

.checkBoxBox {
  width: 48%;
  margin: 0 auto;

  label {
    display: flex !important;
    justify-content: flex-start !important;
    color: black !important;
    font-weight: normal !important;

    .span {
      text-align: left !important;
      margin-left: 4px !important;
      font-size: small;
    }

    input,
    input:hover,
    input:active,
    input:focus {
      padding: 7.5px;
      height: 15px !important;
      width: 15px !important;
      background-color: $white;
      border: 1px solid $jopsy-red-dark !important;
    }

    input:checked::after {
      width: 4px;
      height: 13px;
      transform: rotate(45deg);
      left: 5px;
      top: 0;
      border-width: 0 3px 3px 0;
    }
  }
}
