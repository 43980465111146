@import '../../../styles/base/variables.scss';

.overviewContainer {
    width: 100%;
    height: 100%;
}
.headerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 25px 0;
    font-family: Open Sans;
    font-weight: 800;
  }

.link {
  color: $black;
  display: block;
  @media (min-width: $screen-md-min) {
    width: 700px;
    margin-left: calc(100% - 850px);
  }
  @media (max-width: $screen-xs-min) {
    width: 100%;
  }
}
  
.link:hover,
.link:active,
.link:focus {
  color: $black;
  text-decoration: none;
}

.listItem:hover,
.listItem:active,
.listItem:focus {
  .leftPart,
  .rightPart {
    color: $textLight;
    background-color: #fafbfc;
    img {
      opacity: 0.5;
    }
  }
}
.listItem {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800; 
  height: 90px;
  margin: 20px 20px;

  .leftPart {
    border-radius: 5px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: calc(100% - 120px);
    background-color: $white;
    margin-right: 20px;
    @media (max-width: $screen-xs-min) {
      width: 100%;
      margin-right: 10px;
    }
  }
  .rightPart {
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 90px;
    background-color: $white;
    @media (max-width: $screen-xs-min) {
      width: 80px;
    }
  }
}

.icon {
  height: 25px;
}