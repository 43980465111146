@import '../../styles/base/variables.scss';

.restartModal {
  width: calc(100% - 40px);
  max-width: 600px;
  margin: auto;

  @media (min-width: $max-content-width) {
    max-width: 800px;
  }

  @media (max-width: $screen-xs-min) {
    max-width: calc(100% - 40px);
  }

  .header, .body, .footer, .samePaddingFooter {
    border: 0 none;
    padding-left: 40px;
    padding-right: 40px;
  }

  .header {
    font-weight: bold;
    padding-top: 30px;

    @media (max-width: $screen-xs-min) {
      h3 {
        font-size: 14px !important;
      }
    }
  }
  
  .body {
    font-size: 16px;

    @media (max-width: $screen-xs-min) {
      font-size: 14px;
    }
  }

  .footer {
    padding-bottom: 30px;
    padding-right: 30px !important;
    
    button {
      font-size: 25px;
      @media (max-width: $screen-xs-min) {
        font-size: 3.2vw !important;
        margin-right: 2vw !important;
        padding: 1vw 3vw !important;
      }
    }
  }
  .samePaddingFooter {
    padding-bottom: 30px;
    padding-right: 40px !important;
  }
}

.shareLink {
  display: inline-block;
  width: calc(100% - 50px);
  vertical-align: middle;
}

.copyButton {
  display: inline-block;
  width: 40px;
  margin-left: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  vertical-align: middle;
}

.shareInfo {
  min-height: 30px;
  margin-top: 12px;
  padding: 0 12px;
}