@import '../../styles/base/variables.scss';

.favoriteProfessionsContainer {
  width: 100%;
  height: 100%;

  .buttonWrapper {
    height: 40%;
    button {
      height: 100%;
      img {
        height: 100%;
      }
    }
    button:hover {
      cursor: pointer;
    }
  }
  .filterMenuContainer {
    display: block;
    position: absolute; 
    top: 125px;
    max-width: 450px;
  }

  .headerSection {
    padding: 25px 0 15px 0;

    .mainHeader, .subHeader {
      display: flex;
      justify-content: center;
      justify-items: center;
    }
  }

  .contentList {
    $imageLength: 160px;
    $imagePadding: 20px;

    .tileContainer {
      display: flex;
      background-color: white;
      width: 100%;
      padding: 20px;
      margin: 10px 0;

      @media (max-width: $screen-sm-min) {
        padding: 17px;
      }

      @media (max-width: $screen-xs-min) {
        padding: 14px;
      }

      .professionImage {
        width: $imageLength;
        height: $imageLength;
        margin: 0 calc(#{$imagePadding} / 2) 0 0;
    
        img {
          width: $imageLength;
          height: $imageLength;
        }
  
        img:hover {
          cursor: pointer;
        }
      }

      .tileDescription {
        .title {
          color: $black;
          padding-bottom: 5px;
        }

        .education {
          color: grey;
          .educationItem {
            padding-top: 15px;
          }
        }
      }
      
      @media (max-width: $screen-sm-min) {
        .professionImage {
          width: 20vw;
          height: auto;
          min-width: 95px;
      
          img {
            width: 20vw;
            height: auto;
            min-width: 95px;
          }
        }

        .tileDescription {
          .title, .education {
            padding-bottom: 1vw;
            word-break: break-word;

            @media (max-width: $screen-sm-min) {
              font-size: 17px;
            }

            @media (max-width: $screen-xs-min) {
              font-size: 14px;
            }
          }

          .education {
            .educationItem {
              padding-top: 1.5vw;
            }
          }
        }
      }

      .favoriteIcon {
        flex-grow: 1;
        justify-content: flex-end;

        img {
          float: right;
          width: 40px;
          height: 40px;
          padding: 10px;
          margin-top: -10px;
          margin-right: -10px;

          @media (max-width: $screen-sm-min) {
            width: 38px;
            height: 38px;
          }
  
          @media (max-width: $screen-xs-min) {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .tileContainer:hover {
      cursor: pointer;
    }
  }
}

