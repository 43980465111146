@import '../../../../styles/base/variables.scss';

.card {
  position: relative;
  width: 100%;
  padding-top: 154%;
  background-color: $white;
  border-top-left-radius: 10% 7%;
  border-top-right-radius: 10% 7%;
  border-bottom-left-radius: 10% 7%;
  border-bottom-right-radius: 10% 7%;

  .cardContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .headerContainer {
      width: 100%;
      height: 16.2%;
      padding: 0 7%;
      background-color: $jopsy-red;
      color: $white;
      border-top-left-radius: 9% 40%;
      border-top-right-radius: 9% 40%;
      text-align: center;

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      .smallHeader {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 92%;
        height: 100%;
        word-break: break-word;
        vertical-align: middle;
      }

      .smallerHeader {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 79%;
        height: 100%;
        word-break: break-word;
        vertical-align: middle;
      }

      .createCustomCardHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-top: 30%;
        color: $black;
        overflow: visible;
      }

      .heartContainer,
      .crossContainer {
        display: inline-block;
        width: 13%;
        height: 100%;
        vertical-align: middle;

        button {
          width: 100%;
          height: 100%;
          padding: 0 10%;
          background: none;
          border: none;
          outline: none;
          box-shadow: none;
          cursor: pointer;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .crossContainer {
        button {
          padding: 0 15%;
        }
      }

      .rightMostIcon {
        margin-right: -5%;
      }
    }

    .whiteHeaderContainer {
      position: relative;
      background-color: $white;
      z-index: 1;
    }

    .imageContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 56.5%;
      padding: 7%;
      background-color: $white;

      img {
        max-width: 100%;
        max-height: 100%;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
      }

      .imageOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .cameraButton {
        position: absolute;
        width: 15%;
        height: 15%;
        top: 12%;
        right: 9%;
        padding: 0;
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
        }
      }

      .plusIcon {
        width: 40%;
        height: auto;
      }
    }

    .descriptionContainer {
      width: 100%;
      height: 27.3%;
      padding: 0 7%;
      background-color: $white;
      border-bottom-left-radius: 9% 25%;
      border-bottom-right-radius: 9% 25%;
    }
  }
}
