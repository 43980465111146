@import '../../styles/base/variables.scss';

.resultsExplanationContainer {
    background-color: $background;
    width: 100%;
    height: 100%;

    .resultsExplanationHeader {
        text-align: center;
        margin: 30px 0;
    }

    h3 {
        color: $black;
        margin-bottom: 10px;
    }

    .section {
        background-color: $white;
        margin-bottom: 20px;
        margin-top: 20px;
        padding: 20px 30px;

        p:last-child {
            margin: 0;
        }
    }
}