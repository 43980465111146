@import '../../../../styles/base/variables.scss';

.modal {
  display: flex;
  margin: auto;
  width: 70vw;
  min-width: 300px;
  max-width: 500px;

  > div {
    border-radius: 10px;
  }

  .header, .body, .footer {
    border: 0 none;
  }

  .header {
    display: flex;
    margin: auto;
    padding-bottom: 0;

    h3 {
      color: $black
    }
  }

  .footer {
    display: flex;
    padding-top: 0;
  }

  .body {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    padding: 10px 6% 20px;

    h3 {
      color: $black;
      font-size: 18px;

      @media (max-width: $screen-sm-min) {
        font-size: 16px;
      }

      @media (max-width: $screen-xs-min) {
        font-size: 14px;
      }
    }

    .info {
      text-align: center;
    }

    input {
      font-size: 18px;

      @media (max-width: $screen-sm-min) {
        font-size: 16px;
      }

      @media (max-width: $screen-xs-min) {
        font-size: 14px;
      }
    }

    .modalImageContainer {
      width: 100%;
      margin-top: 30px;
      text-align: center;

      img {
        width: 20%;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }

    .buttonContainerTwoButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }

    .selectWrapper {
      display: inline-block;
      color: $jopsy-red;

      svg, span {
        vertical-align: middle;
      }

      svg {
        margin-right: 10px;
      }

      label {
        cursor: pointer;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    .verticalbuttonContainer {
      display: block;
      width: 100%;
      margin-top: 20px;

      button {
        position: relative;
        width: 100%;
        min-height: 36px;
        margin-top: 10px;

        label {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }

  .createCustomStrengthCardForm {
    input {
      width: 100%;
      border-radius: 5px;
    }
  }
}
