/* eslint no-use-before-define: 0 */

/* stylelint-disable selector-pseudo-class-no-unknown, property-no-unknown */

/*
 * Breakpoints
 * ======================================================================== */
  $screen-iphone-5-min: 320px;
  $screen-iphone-6-min: 375px;
  $screen-iphone-6-plus-min: 414px;
  $screen-xs-min: 480px;  /* Extra small screen / phone */
  $screen-sm-min: 768px;  /* Small screen / tablet */
  $screen-md-min: 992px;  /* Medium screen / desktop */
  $screen-lg-min: 1200px; /* Large screen / wide desktop */
  $screen-height-break-sm: 640px;
  $screen-height-break-xs: 620px;
 /*
  * Colors
  * ======================================================================== */
  $white: #FFFFFF;
  $jopsy-red: #BE0021;
  $jopsy-red-dark: #94001a;
  $guest-orange: #c48f00;
  $lightRed: #ff99aa;
  $rose: #fc9ba8;
  $text: #2A2C33;
  $textLight: #7f8084;
  $background: #F7F8F9;
  $backgroundDarker: #E2E6E9;
  $zeitfresser: #b3b3b3;
  $black: #333333;
  $grey: #777777;
  $link: #0645ad;

  $questionNotViewed: #FFFFFF;
  $questionViewed: #e4e4e7;
  $questionAnswered: #BFC0C1;

  $R-color: #8CA068;
  $I-color: #9471A5;
  $A-color: #D67C31;
  $S-color: #EAB934;
  $E-color: #7DAEAC;
  $C-color: #6986AF;

  $OK-color: #1b73b4;

  $desktop-nav-button-hover-bg: rgb(246, 246, 246);
  $desktop-nav-button-hover-text: #a0a3a9;
  $desktop-nav-button-disabled-text: #d0d4db;
 
 /*
  * Layout
  * ======================================================================== */
  $max-content-width: 1000px;
  $header-height: 60px;
  $footer-height: 70px;
  $header-height-sm: calc(60px * 0.9);
  $footer-height-sm: calc(70px * 0.9);
  $header-height-xs: calc(60px * 0.8);
  $footer-height-xs: calc(70px * 0.8);
  $interests-test-header-height: 50px;
  $interests-test-slider-container-height: 135px;

 /*
  * Z-Index Stack
  * ======================================================================== */
  $layer-bottom: 0;
  $layer-intermediate: 10;
  $layer-top: 20;
  $layer-max-top: 9999;
