@import '../../../styles/base/variables.scss';

.loginContainer {
  margin-top: 10vh;
  margin-bottom: 20px;
  display: block;

  @media (max-width: $screen-sm-min){
    margin-top: 40px;
  }
};
