@import '../../../../styles/base/variables.scss';

.modal {
    background-color: transparent;
    align-items: center;
}

.modalContent {
    max-width: 100%;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.headerText {
    margin-bottom: 30px;
    font-family: open sans;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
}

.suggestionsContainer {
    display: inline-flex;
    flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: auto;
}
.abortButton {
    margin-left: 15px;
    min-width: calc(100% - 30px);
    margin-bottom: 10px;
}

.button {
    margin-left: 15px;
    min-width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    padding: 10px;
    margin-bottom: 10px;
    white-space: normal;
}
