@import '../../../styles/base/variables.scss';

.overviewContainer {
    width: 100%;
    height: 100%;
}
.headerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: Open Sans;
  }
.headerLine{
  text-align: center;
  display: block;
  width: 250px;
  margin-top: 5px;
  h1 {
    margin: 10px 0px;
    color: $black;
    font-size: 20px;
  }
  a {
    color: $link;
    font-weight: 800;
  }
  @media (max-height: $screen-height-break-xs){
    font-size: 13px;
    margin-top: 3px;
    h1 {
      margin: 0px 0px;
      font-size: 15px;
    }
    a {
      font-weight: 600;
    }
  }

}
.headerWrapper {
  width: 250px;
  padding-top: 20px;
  @media (max-height: $screen-height-break-xs) {
    padding-top: 5px;
  }
}

.bubbleWrapper {
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  @media (max-height: $screen-height-break-sm){
    margin-top: -20px;
  }
  @media (max-height: $screen-height-break-xs){
    margin-top: -80px;
  }
}
.bubbleModal {
  div[class*="modal-content"] {
    padding-top: 80px;
    background-color: transparent;
    align-items: center;
    border: none;
  }
}

.bubble {
  width: 350px;
  height: 350px;
  background-color: $white;
  border-radius: 175px;
  display:flex;
  flex-direction: column;
}
.questionContainer {
  height: 60px;
  margin-top: 55px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 10px;

}
.questionText {
  font-family: open sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}
.suggestionButtonContainer {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.answerContainer {
  height: 150px;
  margin-left: 50px;
  margin-right: 50px;
}
.answerInputText {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-family: 'open sans';
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  border-color: $black;
  border-width: 2px;
}
.answerText {
  height: 100px;
  font-family: 'open sans';
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  white-space: normal;
  overflow-y: auto;
  overflow-wrap: break-word;
  padding-top: 10px;
}
.iconsBar {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-items: center;
  justify-content: center;
}
.editIconContainer:active,
.editIconContainer:focus,
.editIconContainer:hover,
.editIconContainer {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}
.editIcon {
  width: 20px;
  height: 20px;
}
.currentAnswer {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  font-weight: 400;
  max-width: 100%;
  max-height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
}
.smallBubble,
.smallBubble:active,
.smallBubble:focus {
  background-color: #FFFFFF;
  border-radius: 100px;
  border: none;
  color: #000000;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.smallBubble:hover { 
  background-color: #FFFFFF !important;
  border-radius: 100px !important;
  border: none !important;
  color: $textLight !important;
  background-color: #fafbfc;
  img {
    opacity: 0.5;
  }
}