 @import '../../styles/base/variables.scss';

.homeScreenContainer {
  width: 100%;
  height: 100%;

  .guestBanner, .results, .professions, .offers {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  .results {
    padding-top: 15px;
  }

  .guestBanner {
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    background-color: $white;
    color: $jopsy-red;
    text-align: center;
    font-weight: bold;

    button {
      margin-top: 10px;
    }
  }

  .interestBars {
    width: 100%;
    height: 40%;
    min-height: 200px;
    max-height: 400px;
    background-color: $white;

    .interestBarsInner {
      max-width: 600px;
      height: 100%;
      margin: auto;
      padding: 20px 15px 0 15px;

      @media (max-width: $screen-sm-min) {
        padding-top: 10px;
      }

      @media (max-width: $screen-xs-min) {
        padding-top: 0;
      }

      .column {
        display: inline-block;
        width: 30.33%;
        height: 100%;
        min-height:100%;
        margin: 0 1.5%;
    
        .bar {
          width: 100%;
          height: calc(100% - 40px);

          @media (max-width: $screen-sm-min) {
            height: calc(100% - 30px);
          }
  
          @media (max-width: $screen-xs-min) {
            height: calc(100% - 20px);
          }
        }
    
        .label {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          text-align: center;
          font-size: 22px;
          font-weight: 600;
          width: 100%;
          height: 40px;
          position: relative;
    
          @media (max-width: $screen-sm-min) {
            height: 30px;
            font-size: 18px;
          }

          @media (max-width: $screen-xs-min) {
            height: 20px;
            font-size: 14px;
          }
        }
      }

      .column:hover {
        cursor: pointer;
      }
    }
  }

  .accuracyBarContainer {
    width: 100%;
    background-color: $white;
    padding: 5vh 0 20px 0;

    @media (max-width: $screen-sm-min) {
      padding: 20px 0 20px 0;
    }

    .accuracyBarContainerInner {
      max-width: calc(600px - 1.5%);
      margin: auto;
      padding: 0 calc(15px + 1.5%);

      .accuracyBarHeader {
        text-align: center;
        font-size: 22px;
        margin-bottom: 5px;
    
        @media (max-width: $screen-sm-min) {
          font-size: 19px;
        }

        @media (max-width: $screen-xs-min) {
          font-size: 16px;
        }

        span {
          vertical-align: middle;
        }

        button {
          width: 30px;
          height: 30px;
          margin-left: 10px;
          line-height: 0;
          vertical-align: middle;
          background: none;
          border: 1px solid $black;
          border-radius: 50%;
          box-shadow: none;
          outline: none;
          font-size: 16px;

          @media (max-width: $screen-sm-min) {
            width: 28px;
            height: 28px;
            font-size: 14px;
          }
  
          @media (max-width: $screen-xs-min) {
            width: 26px;
            height: 26px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .favorites {
    width: 100%;
    background-color: $white;
  }
}
