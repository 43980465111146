@import '../../styles/base/variables.scss';

.datePickerContainer {
  padding-bottom: 10px;

  .datePicker {
    margin-right: 10px;
  }
}

.refreshButton {
  width: 28px;
  height: 28px;
  padding: 0 !important;

  svg {
    margin-top: -3px;
  }
}

.leftStatisticsContainer {
  display: inline-block;
  width: 57%;
  padding-right: 5px;
  text-align: right;
  font-size: 20px;
}

.rightStatisticsContainer {
  display: inline-block;
  width: 43%;
  padding-left: 5px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.pieChartContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .pieChartRowContainer {
    display: block;
    width: 50%;
    min-width: 450px;
    padding: 30px;
    margin: auto;
    text-align: center;
  }
}

.chartContainer {
  display: inline-block;
  width: 100%;
  max-width: 800px;
  padding: 10px;
  vertical-align: top;
  min-width: 300px;
  height: auto;
}

.lineChartRowContainer {
  display: block;
  width: 100%;
  padding: 30px;
  text-align: center;

  .lineChartContainer {
    display: block;
    width: 80%;
    padding: 10px;
    min-width: 400px;
    margin: auto;
  }

}

.rowContainer {
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 20px 0 10px 0;
  flex-wrap: wrap;
  justify-content: center;

  .buttonText {
    margin-right: 10px;
  }
}

.statsRowContainer {
  padding-left: 10px;
}

.noteContainer {
  display: block;
  width: 100%;
  padding: 50px 50px 100px;
  text-align: left;
  font-weight: bold;
}
