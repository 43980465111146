 @import '../../styles/base/variables.scss';

.contentContainer {
  padding: 0 50px;

  @media (max-width: $screen-sm-min) {
    margin-bottom: 50px;
    padding: 0 20px;
  }

  @media (max-width: $screen-xs-min) {
    margin-bottom: 40px;
    padding: 0 15px;
  }

  h1 {
    color: $white;
    text-align: center;
    padding: 40px 80px;

    @media (max-width: $screen-sm-min) {
      padding: 35px 30px;
      font-size: 21.5px;
    }

    @media (max-width: $screen-xs-min) {
      padding: 30px 15px;
      font-size: 19px;
    }
  }
  
  .offers {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding: 20px;
  }
  
  .selectionWrapper {
    display: flex;
    justify-content: center;
  
    .selections {
      margin: auto;
    
      .selectWrapper {
        width: 100%;
        display: block;
        color: $white;
        font-weight: bold;

        label {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 16px 20px;
          margin: 0;
          font-size: 22px;

          svg, span {
            min-width: 16px;
            vertical-align: middle;
          }

          span {
            display: inline-block;
            margin-left: 10px;
          }

          @media (max-width: $screen-sm-min) {
            font-size: 18px;
            padding: 12px 19px;
          }

          @media (max-width: $screen-xs-min) {
            font-size: 16px;
            padding: 8px 15px;
          }
        }

        label:hover {
          cursor: pointer;
        }
      }
    }
  }

  button {
    margin: 10px 0 20px 0;
  }
}
