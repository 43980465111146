@import '../../../styles/base/variables.scss';

.activitiesContainer {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.headerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0 25px 0;

  h1 {
    color: $black;
    text-align: center;
  }

  h2 {
    margin-top: 10px;
    color: $black;
    text-align: center;
    font-weight: normal;
  }
}

.activities {
  padding: 15px 10px 0;
}

.experiences {
  padding: 20px 10px;
}

.pieChartContainer {
  padding: 20px 0;
  display: block;
  width: 70%;
  margin: auto;
  max-width: 400px;
  justify-content: center;
  text-align: center;

  .plusIcon {
    width: 40px;
    padding: 20px 0 10px 0;
  }

  .plusIcon:hover {
    cursor: pointer;
  }
}

.experienceInputContainer {
  display: inline-flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  padding: 15px 0;

  form {
    display: flex;
    justify-content: center;
    width: 100%;

    .experienceInputField {
      width: calc(100% - 100px);
      height: 40px;
      max-width: 510px;
      padding-left: 5px;
      border: none;
      border-radius: 5px
    }
  
    .plusIconNoBorder {
      width: 40px;
      height: 40px;
      margin-left: 20px;
      outline: none;
      box-shadow: none;
    }

    @media(max-width: $screen-xs-min) {
      .experienceInputField {
        width: calc(100% - 80px);
      }
      .plusIconNoBorder {
        margin-left: 10px;
      }
    }
  }
}

.experienceTilesContainer {
  width: 100%;
  margin: auto;
  text-align: center;
  display: block;

  .tile {
    display: inline-flex;
    margin: 20px;
    min-height: 200px;
    width: calc(35% - 40px);
    max-width: 300px;
    min-width: 100px;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    vertical-align: middle;
  
    .tileContentContainer {
      height: fit-content;
      overflow-wrap: break-word;
      margin: auto;
      text-align: center;
      max-width: 100%;
      color: $white;
    }
  }
}

@media(max-width: $screen-sm-min) {
  .experienceTilesContainer {
    width: 95vw;

    .tile {
      margin: 2vw;
      width: calc(50% - 4vw);
    }
  }
}

.removeTileButton {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.removeTileButton:hover {
  cursor: pointer;
}

@media(max-width: $screen-xs-min) {
  .removeTileButton {
    width: 5vw;
    height: 5vw;
    min-height: 20px;
    min-width: 20px;
  }
}

.experienceMenuTilesContainer {
  width: 70%;
  display: inline-block;
  justify-content: center;
  text-align: center;
  margin: 0 15%;
  margin-bottom: 30px;
  
  .experienceMenuTile {
    background-color: $white;
    display: inline-block;
    width: 40%;
    max-width: calc(50% - 20px);
    height: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin: 10px;
    border-radius: 10px;
    padding-bottom: 20px;

    .experienceMenuIcon {
      display: block;
      width: 50%;
      margin: 10px auto 0 auto;
      padding: 6%;
    }
  }

  .experienceMenuTile:hover {
    cursor: pointer;
  }
}

@media(max-width: $screen-sm-min) {
  .experienceMenuTilesContainer {
    .experienceMenuTile {
      width: 175px;
      margin: 10px;

      h3 {
        font-size: 2.5vw !important;
      }
    }
  }
}

@media(max-width: $screen-xs-min) {
  .experienceMenuTilesContainer {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;

    .experienceMenuTile {
      width: calc(50% - 20px);
      max-width: calc(50% - 15px);
      margin: 7.5px;

      h3 {
        font-size: 3.5vw !important;
      }
    }
  }
}