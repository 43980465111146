@import '../../../../styles/base/variables.scss';

.modal {
  display: flex;
  margin: auto;
  width: 40vw;
  min-width: 350px;
  max-width: 430px;

  .header, .body, .footer {
    border: 0 none;
  }

  .body, .footer {
    display: flex;
    padding-top: 0;
  }

  .header {
    margin: 10px auto 12px;
    h3 {
      color: $black;
    }
  }

  .body {
    width: 100%;
    div[class*='form-group'], form, fieldset {
      margin: 0;
      width: 100%;
    }
  }
}

.formFieldContainer {
  width: 100%;
}

.buttonContainer {
  width: 500px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-top: 30px;
}