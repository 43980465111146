@import '../../styles/base/variables.scss';

.modal {
  display: flex;
  margin: auto;
  width: 70vw;
  min-width: 300px;
  max-width: 500px;

  > div {
    border-radius: 10px;
  }

  .header, .body, .footer {
    border: 0 none;
  }

  .header {
    display: flex;
    margin: auto;
    padding-bottom: 0;

    h3 {
      color: $black
    }
  }

  .footer {
    display: flex;
    padding-top: 0;
  }

  .body {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    padding: 10px 6% 20px;

    h3 {
      color: $black;
      font-size: 18px;

      @media (max-width: $screen-sm-min) {
        font-size: 16px;
      }

      @media (max-width: $screen-xs-min) {
        font-size: 14px;
      }
    }

    .info {
      text-align: center;
    }

    .resetText {
      margin-bottom: 20px;
    }

    .buttonContainerTwoButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }

    .selectWrapper {
      margin-top: 10px;
      display: block;
      color: $black;

      svg, span {
        vertical-align: middle;
      }

      svg {
        margin-right: 10px;
      }

      label {
        cursor: pointer;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
}
