@import '../../styles/base/variables.scss';

.headerContainer {
  background-color: $white;
  width: 100%;
  height: $header-height;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: $screen-sm-min) {
    height: $header-height-sm;
    grid-template-rows: $header-height-sm;
  }

  @media (max-width: $screen-xs-min) {
    height: $header-height-xs;
    grid-template-rows: $header-height-xs;
  }

  .backButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    height: 100%;
    margin: 0 10px;

    button {
      height: 40%;

      img {
        height: 100%;
      }
    }

    button:hover {
      cursor: pointer;
    }
  }

  .middle {
    display: flex;
    flex: 1;
    height: 100%;
    justify-self: center;
    align-self: center;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
    }
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    height: 100%;
    margin: 0 10px;

    .logo {
      height: 40%;
      width: auto;
    }
  }
}



