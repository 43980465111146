@import '../../styles/base/variables.scss';

.modeSelectionContainer {
  width: 100%;
  height: 100%;

  .youngModeContainer,
  .oldModeContainer {
    position: relative;
    width: 100%;
    height: 49.5%;

    img {
      position: absolute;
      width: 100%;
      height: 75%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      object-position: 50% 20%;
    }

    .textContainer {
      position: absolute;
      bottom: 35%;
      left: 5%;
      right: 0;
      font-family: open sans;
      font-weight: 900;
      color: $white;
      font-size: 70px;

      @media (max-width: $screen-sm-min), (max-height: 900px) {
        font-size: 60px;
      }

      @media (max-width: $screen-xs-min), (max-height: 700px) {
        font-size: 50px;
      }

      @media (max-height: 500px) {
        font-size: 40px;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 25%;
      bottom: 0;
      left: 0;
      right: 0;

      button {
        padding: 20px 25px;
        margin-right: 50px;
        font-family: open sans;
        font-weight: 600;
        font-size: 28px;
        border: 1px solid $white;
        border-radius: 25px;
        outline: none;
        box-shadow: none;
        color: $white;

        @media (max-width: $screen-sm-min), (max-height: 900px) {
          padding: 16px 22px;
          margin-right: 40px;
          font-size: 24px;
          border-radius: 20px;
        }
  
        @media (max-width: $screen-xs-min), (max-height: 700px) {
          padding: 12px 18px;
          margin-right: 30px;
          font-size: 20px;
          border-radius: 15px;
        }
  
        @media (max-height: 500px) {
          padding: 8px 14px;
          font-size: 16px;
        }
      }
    }
  }

  .youngModeContainer {
    background-color: #d8687b;

    .buttonContainer {
      background-color: #d8687bdd;

      button {
        background-color: #d8687bdd;
      }

      button:hover {
        background-color: $white;
        color: #d8687bdd;
      }
    }
  }

  .oldModeContainer {
    background-color: #9d9ea1;

    .buttonContainer {
      background-color: #9d9ea1dd;

      button {
        background-color: #9d9ea1dd;
      }

      button:hover {
        background-color: $white;
        color: #9d9ea1dd;
      }
    }
  }

  .spacer {
    background-color: $white;
    width: 100%;
    height: 1%;
  }
}
