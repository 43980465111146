@import '../../styles/base/variables.scss';

.menuContainer {
    background-color: $jopsy-red;
    border-radius: 7px;
    padding: 15px 40px;

    @media (max-width: $screen-sm-min) {
        padding: 15px 30px;
    }

    .menuBody {
        display: block;

        a {
            text-decoration: none;
        }
    }

    .menuItem {
        color: $white;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0;
        text-decoration: none !important;
        background: none;
        border: none;
        outline: none;
        text-align: left;
        margin: 0;

        @media (max-width: $screen-sm-min) {
            font-size: 18px;
            padding: 8px 0;
        }

        @media (max-width: $screen-xs-min) {
            font-size: 16px;
            padding: 6px 0;
        }
    }

    .logoutItem {
        width: 100%;
        max-width: 290px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (max-width: $screen-sm-min) {
            max-width: 215px;
        }

        @media (max-width: $screen-xs-min) {
            max-width: 170px;
        }
    }

    .menuItem:hover {
        cursor: pointer;
    }

    .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 60px 40px 0 0;
        border-color: $jopsy-red transparent transparent transparent;
        right: 5px;
        bottom: -50px;
        position: absolute;
    }

    @media (min-width: $screen-sm-min) {
        .triangle {
            border-width: 60px 40px 0 0;
            right: 10px;
            bottom: -50px;
            position: absolute;
        }
    }
}
