@import '../../styles/base/variables.scss';

.contentBodyContainer {
  background-color: $background;
  height: calc(100% - (#{$header-height} + #{$footer-height}));
  width: 100%;
  overflow-y: auto;

  @media (max-width: $screen-sm-min) {
    height: calc(100% - (#{$header-height-sm} + #{$footer-height-sm}));
  }

  @media (max-width: $screen-xs-min) {
    height: calc(100% - (#{$header-height-xs} + #{$footer-height-xs}));
  }
}