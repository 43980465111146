@import '../../../styles/base/variables.scss';

.favoriteJobExpectationLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin: 5px 0;
  padding: 8px 0;
  background-color: $white;

  .smiley {
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
    margin: 0 10px;
  }

  .text {
    flex: 1;
    text-align: center;
  }

  .favoriteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
    margin: 0 10px;
    padding: 0;
    background: none;
    border: none;
    outline: none;

    .heart {
      width: 50%;
      height: auto;
    }
  }

  .favoriteButton:hover {
    cursor: pointer;
  }
}
