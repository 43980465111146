@import '../../styles/base/variables.scss';

.exportScreenContainer {
  width: 100%;
  height: 100%;

  .pdfContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    height: calc(100% - 100px);
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden !important;

    .pageContainer {
      height: auto !important; 
      width: 100%;
      display: flex ;
      justify-content: center;

      canvas {
        margin-bottom: 1.2em;
        width: 70% !important;
        height: 70% !important;

        @media (max-width: $screen-sm-min) {
          width: 80% !important;
          height: 80% !important;
        }

        @media (max-width: $screen-xs-min) {
          width: 90% !important;
          height: 90% !important;
        }
      }

      > div {
        height: 100% !important;
      }
    }
  }

  .downloadButtonWrapper {
    width: 100%;
    padding: 1em 0;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60px;
    background-color: white;
  }

  .invalidShare {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    color: $jopsy-red;
    font-weight: 700;
    font-size: 30px;
    font-family: Open Sans;
  }
}



