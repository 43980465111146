@import './variables.scss';

a {
  color: $jopsy-red;
}

a:hover,
a:active,
a:focus {
  color: $jopsy-red-dark;
}
