@import '../../../styles/base/variables.scss';

.jobExpectationsContainer {
  width: 100%;
  height: 100%;

  .jobExpectationsContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .headerSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 25px 0 50px 0;
  
      h1 {
        color: $black;
        text-align: center;
      }
  
      h2 {
        margin-top: 10px;
        color: $black;
        text-align: center;
        font-weight: normal;
      }
    }
  
    .jobExpectationsList {
      width: 100%;
      position: relative;
    }
  
    .bottomInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px 0 25px 0;
      text-align: center;
    }
  }
}

.previousSlideButton,
.previousSlideButton:active,
.previousSlideButton:focus,
.nextSlideButton,
.nextSlideButton:active,
.nextSlideButton:focus {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  transform: translateY(-50%);

  img {
    width: 50px;
    height: 50px;

    @media (max-width: $screen-sm-min) {
      width: 43px;
      height: 43px;
    }
  
    @media (max-width: $screen-xs-min) {
      width: 36px;
      height: 36px;
    }
  }
}

.previousSlideButton:hover,
.nextSlideButton:hover {
  cursor: pointer;
}

.previousSlideButton:disabled,
.nextSlideButton:disabled {
  opacity: 0.5;
}

.previousSlideButton:disabled:hover,
.nextSlideButton:disabled:hover {
  cursor: not-allowed;
}

.previousSlideButton {
  left: 5px;
}

.nextSlideButton {
  right: 5px;
}
