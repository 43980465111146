@import '../../styles/base/variables.scss';

.professionDetailScreenContainer {
  width: 100%;
  height: 100%;

  .contentContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    width: 100%;
  
    .titleContainer {
      position: relative;
      width: 100%;
      padding: 20px 50px 25px 50px;
      background-color: $white;

      .heading {
        justify-self: center;
        text-align: center;
        word-break: break-word;

        .title {
          color: $black;
          padding-bottom: 15px;
        }

        .education {
          color: grey;
          word-break: break-word;

          .educationItem {
            padding-top: 5px;
          }
        }
      }

      .favoriteIcon {
        position: absolute;
        top: 20px;
        right: 20px;

        img {
          float: right;
          width: 40px;
          height: 40px;
          padding: 10px;
          margin-top: -10px;
          margin-right: -10px;

          @media (max-width: $screen-sm-min) {
            width: 38px;
            height: 38px;
          }
  
          @media (max-width: $screen-xs-min) {
            width: 36px;
            height: 36px;
          }
        }

        cursor: pointer;
      }
    }

    .professionImage {
      width: 100%;
      display: block;
      padding-bottom: 25px;
      background-color: white;
      text-align: center;

      img {
        width: 60%;
        max-width: 500px;
        height: auto;
      }
      
      @media (max-width: $screen-xs-min) {
        img {
          width: 70%;
          max-width: 250px;
          height: auto;
        }
      }
    }

    .description {
      width: 100%;
      margin-top: 15px;
      background-color: white;
      padding: 20px;
    }

    @media (max-width: $screen-xs-min) {
      .description {
        padding: 15px;
      }
    }
  }
}
  


