@import '../../styles/base/variables.scss';

.appContainer {
  width: 100%;
  height: 100%;
  background-color: $backgroundDarker;

  .appContent {
    width: 100%;
    max-width: $max-content-width; // 100vh also worked well
    height: 100%;
    margin: auto;
    background-color: $white;
  }
}