 @import '../../styles/base/variables.scss';

.bubbleContainer {
  width: 100%;
  height: 100%;
  padding: 30px 30px 60px 30px;

  @media (max-width: $screen-sm-min) {
    padding: 20px 20px 50px 20px;
  }

  @media (max-width: $screen-xs-min) {
    padding: 10px 10px 40px 10px;
  }

  .bubble {
    position: relative;
    width: 100%;
    max-width: 700px;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    background-color: $jopsy-red;
    display: block;
    border-radius: 5px;
  
    @media (max-width: $screen-sm-min) {
      margin-bottom: 50px;
    }
  
    @media (max-width: $screen-xs-min) {
      margin-bottom: 40px;
    }
  }

  .bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 90%;
    width: 0;
    height: 0;
    border: 2.3em solid transparent;
    border-top-color: $jopsy-red;
    border-bottom: 0;
    border-left: 0;
    margin-left: -1.15em;
    margin-bottom: -2.3em;
  }
}
