@import '../../styles/base/variables.scss';

.infographicScreenContainer {
  width: 100%;
  height: 100%;
  background-color: $backgroundDarker;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img:hover {
    cursor: pointer;
  }
}
