 @import '../../styles/base/variables.scss';

.offersContainer {
  width: 100%;
  height: 100%;

  .headerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 25px 0;
  }

  .info {
    display: block;
    background-color: $white;
    text-align: center;
    padding: 40px 80px;
    margin-bottom: 9px;

    @media (max-width: $screen-xs-min) {
      padding: 40px 20px;
    }
  
    a {
      display: block;
      text-decoration: none;
      font-weight: bold;
      color: $link;
      padding-top: 20px;
    }
      
    .subinfo {
      display: block;
      padding-top: 20px;
    }
  }

  .afterInfo {
    margin-top: 20px;
    width: 100%;

    .kaerntenLogo {
      width: 75%;
      max-width: 350px;
      margin: 35px auto;

      img {
        width: 100%;
        height: auto;
      }
    }
    
    .test {
      background-color: $white;
      padding-bottom: 30px;
    }

    .buttonInfo {
      display: block;
      text-align: center;
      padding: 40px 80px 30px 80px;

      @media (max-width: $screen-xs-min) {
        padding: 40px 20px;
      }
    }

    .buttonBox {
      margin: 0 10%;
    }

    .buttonContainer {
      padding: 7px;
      margin: 0 auto;

      @media (min-width: $screen-md-min) {
        width: 50%;
        display: inline-block;
      }

      .buttonLink {
        width: 100%;

        button {
          width: 100%;
          text-transform: uppercase;

          span {
            padding: 0 0 0 7px;
            position: relative;
            bottom: 2px;
          }
        }
      }
    }
  }

  .listItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px 0;
    background-color: $white;
    height: fit-content;

    @media (max-width: $screen-md-min) {
      display: block;
    }
  
    .leftPart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 20px;
      text-align: center;

      @media (max-width: $screen-md-min) {
        display: block;
      }

      p {
        text-align: start;
        padding-top: 10px;
        margin-bottom: 0;

        @media (max-width: $screen-md-min) {
          text-align: center;
        }
      }
    }

    .rightPart {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      background-color: $white;

      @media (max-width: $screen-md-min) {
        display: block;
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
      }

      a {
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.noButton {
  border: 0px solid !important;
}
.noButton:hover {
  background-color: unset !important;
}

.headerLink {
  font-size: 20px;

  @media (max-width: $screen-sm-min) {
    font-size: 18px;
  }

  @media (max-width: $screen-xs-min) {
    font-size: 17px;
  }
}
