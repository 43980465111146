@import '../../styles/base/variables.scss';

.identificationScreenContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .textContainer {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .inputContainer {
    input {
      width: 300px;
      padding: 3px;
      background-color: $jopsy-red;
      border: none;
      border-radius: 10px;
      color: $white;
      text-align: center;
      font-size: 30px;
      outline: none;
      box-shadow: none;
    }
  }

  .errorContainer {
    width: 400px;
    height: 100px;
    margin-top: 20px;
    font-size: 20px;
    color: $jopsy-red;
    text-align: center;
  }
}
