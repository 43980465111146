@import '../../styles/base/variables.scss';

.startScreenContainer {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;

  .akLogo {
    width: auto;
    max-width: 100%;
    max-height: 15vh;
    margin: 9vh 0 4vh 0;

    @media (max-width: $screen-sm-min) {
      max-height: 13vh;
      margin: 8vh 0 3vh 0;
    }

    @media (max-width: $screen-xs-min) {
      max-height: 11vh;
      margin: 4vh 0 2vh 0;
    }
  }

  .textContainer {
    font-size: 16px;
    font-weight: bold;

    @media (min-width: $screen-md-min) {
      font-size: 18px;
    }

    @media (min-width: $screen-lg-min) {
      font-size: 30px;
    }
  }

  .buttonContainer {
    width: 100%;
    padding: 50px 0;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      padding: 70px 0;
    }

    button {
      width: 190px;
      font-size: 16px;
      margin-top: 10px;

      @media (min-width: $screen-xs-min) {
        width: 210px;
        font-size: 18px;
      }

      @media (min-width: $screen-lg-min) {
        width: 220px;
        font-size: 20px;
      }
    }

    .explanationVideoButtonContainer {
      margin-top: 20px;

      span {
        padding: 0 0 0 7px;
        position: relative;
        bottom: 2px;
      }
    }
  }
}
