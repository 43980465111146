@import './variables.scss';

html,
body {
  color: $black;
  font-weight: 100;
  font-size: 16px;
  font-family: Open Sans;
  line-height: 1.375;
  color: $black;

  @media (max-width: $screen-xs-min) {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $jopsy-red;
  font-family: Open Sans;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;

  @media (max-width: $screen-sm-min) {
    font-size: 22px;
  }

  @media (max-width: $screen-xs-min) {
    font-size: 20px;
  }
}

h2 {
  font-size: 22px;

  @media (max-width: $screen-sm-min) {
    font-size: 20px;
  }

  @media (max-width: $screen-xs-min) {
    font-size: 18px;
  }
}


h3 {
  font-size: 20px;

  @media (max-width: $screen-sm-min) {
    font-size: 18px;
  }

  @media (max-width: $screen-xs-min) {
    font-size: 16px;
  }
}
