@import '../../../styles/base/variables.scss';

.nextStepsContainer {
  width: 100%;
  height: 100%;
  div[class*='form-group'] {
    margin-bottom: 0;
  }
  .contentHeader {
    width: 100%;
    height: 60px;
    display: flex;
    margin: auto;
    vertical-align: middle;
    h3 {
      width: fit-content;
      margin: auto;
      color: $black;
    }
  }
  .entryContainer {
    width: 100%;
    display: inline-flex;
    margin: 5px 0;
    padding: 10px 20px;
    background-color: $white;
    .entryInfo {
      padding: 0 10px;
      margin: 0;
      display: inline-block;
    }
    .iconContainer{
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      
      .icon {
        margin-top: 5px;
        width: 5vw;
        height: 5vw;
        max-width: 15px;
        max-height: 15px;
        justify-self: flex-end;
        cursor: pointer;
      }
    }
  }
}

.plusIconContainer {
  width: 100%;
  .plusIcon {
    display: block;
    width: 50px;
    height: 50px;
    margin: 10px auto 20px;
    cursor: pointer;
  }
}

.fragDieAKContainer {
  width: 100%;
  .fragDieAKLogo {
    display: block;
    width: 80%;
    max-width: 400px;
    margin: 50px auto 50px auto;
  }
}