@import '../../styles/base/variables.scss';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: $layer-max-top;
  overflow: hidden;

  span {
    z-index: $layer-max-top + 1;
  }
}

.spinnerWrapper {
  display: block;
  width: 100%;
  z-index: $layer-max-top + 1;
}

.loadingText {
  display: block;
  width: 100%;
  color: $white;
  text-align: center;
}
