@import './variables.scss';

.btn {
  padding: 5px 20px !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  border-style: solid !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: Open Sans !important;
  font-weight: 800 !important;

  @media (max-width: $screen-xs-min) {
    font-size: 14px !important;
  }
}

.btn:hover {
  cursor: pointer !important;
}

.btn-wide {
  padding: 5px 50px;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: $jopsy-red !important;
  border-color: $jopsy-red !important;
  color: $white !important;
}

.btn-primary:hover {
  background-color: $jopsy-red-dark !important;
  border-color: $jopsy-red-dark !important;
  color: $white !important;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: $white !important;
  border-color: $jopsy-red !important;
  color: $jopsy-red !important;
}

.btn-secondary:hover {
  background-color: $backgroundDarker !important;
  border-color: $jopsy-red !important;
  color: $jopsy-red !important;
}

.btn-secondary:disabled {
  background-color: $white !important;
  border-color: $jopsy-red-dark !important;
  color: $jopsy-red-dark !important;
}

.btn-no-style,
.btn-no-style:active,
.btn-no-style:focus,
.btn-no-style:hover {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}



.R-button {
  background-color: $white;
  border-color: $R-color;
  color: $R-color;
}

.R-button:hover {
  background-color: $backgroundDarker;
  border-color: $R-color;
  color: $R-color;
}

.I-button {
  background-color: $white;
  border-color: $I-color;
  color: $I-color;
}

.I-button:hover {
  background-color: $backgroundDarker;
  border-color: $I-color;
  color: $I-color;
}

.A-button {
  background-color: $white;
  border-color: $A-color;
  color: $A-color;
}

.A-button:hover {
  background-color: $backgroundDarker;
  border-color: $A-color;
  color: $A-color;
}

.S-button {
  background-color: $white;
  border-color: $S-color;
  color: $S-color;
}

.S-button:hover {
  background-color: $backgroundDarker;
  border-color: $S-color;
  color: $S-color;
}

.E-button {
  background-color: $white;
  border-color: $E-color;
  color: $E-color;
}

.E-button:hover {
  background-color: $backgroundDarker;
  border-color: $E-color;
  color: $E-color;
}

.C-button {
  background-color: $white;
  border-color: $C-color;
  color: $C-color;
}

.C-button:hover {
  background-color: $backgroundDarker;
  border-color: $C-color;
  color: $C-color;
}

.OK-button {
  background-color: $white;
  border-color: $OK-color;
  color: $OK-color;
}

.OK-button:hover  {
  background-color: $backgroundDarker;
  border-color: $OK-color;
  color: $OK-color;
}
