@import '../../../../styles/base/variables.scss';

.favoriteStrengthsScreenContainer {
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    .headerSection {
      text-align: center;

      h1 {
        margin: 30px 0 10px 0;
        color: $black;
      }

      .selectWrapper {
        margin-top: 10px;
  
        svg, span {
          vertical-align: middle;
        }

        svg {
          margin-right: 10px;
        }

        label {
          cursor: pointer;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }

  .listCardWrapper {
    width: calc(0.5 * (100vh - (#{$header-height} + #{$footer-height} + 130px)));
    margin: auto;
  }
}
