@import '../../styles/base/variables.scss';

.interestsOverviewScreenContainer {
  width: 100%;
  height: 100%;

  .headerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 25px 0;
  }

  .gradientBarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 30.33%;
    height: 36vh;
    max-height: 400px;
    margin: auto;

    .gradientBar {
      width: 100%;
      max-width: 150px;
      height: 100%;

      @media (max-width: $screen-sm-min) {
        max-width: 120px;
      }
    
      @media (max-width: $screen-xs-min) {
        max-width: 90px;
      }
    }
  }

  .interestSection {
    width: 100%;
    margin-top: 60px;
    padding: 0 15px;

    .interestLine {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 400px;
      height: 33px;
      margin: 15px auto;

      @media (max-width: 300px) {
        display: block;
        max-width: unset;
        height: auto;
      }

      .percentageLabel {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 100%;
        margin-right: 15px;
        color: $white;
        font-weight: 600;

        @media (max-width: 400px) {
          width: 80px;
        }

        @media (max-width: 300px) {
          width: 100%;
          height: 33px;
          margin: 0 0 5px;
        }
      }

      .buttonLinks {
        display: flex;
        flex-direction: row;
        width: 100%;

        .buttonLink {
          flex: 1;
          margin-right: 5px;
  
          button {
            width: 100%;
            text-transform: uppercase;
          }
        }
  
        .playButtonLink {
          button {
            padding: 9px 8px 8px 10px !important;
            line-height: 0;
          }
        }
      }
    }
  }
}
