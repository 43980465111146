@import '../../styles/base/variables.scss';

.accuracyModal {
  width: calc(100% - 40px);
  max-width: 600px;
  margin: auto;

  @media (min-width: $max-content-width) {
    max-width: 800px;
  }

  @media (max-width: $screen-xs-min) {
    max-width: calc(100% - 40px);
  }

  .header, .body, .footer {
    border: 0 none;
    padding-left: 40px;
    padding-right: 40px;
  }

  .header {
    font-weight: bold;
    padding-top: 30px;

    @media (max-width: $screen-xs-min) {
      h3 {
        font-size: 14px !important;
      }
    }
  }
  
  .body {
    font-size: 16px;

    @media (max-width: $screen-xs-min) {
      font-size: 14px;
    }
  }

  .footer {
    padding-bottom: 30px;
    padding-right: 30px !important;
    button {
      font-size: 25px;
    }
    @media (max-width: $screen-xs-min) {
      padding-right: 6vw !important;
    }
  }
}