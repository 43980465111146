@import '../../styles/base/variables.scss';

.interestBarContainer {
  width: 100%;
  height: 100%;
}

.percentage {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 5px;

  @media (max-width: $screen-sm-min) {
    font-size: 26px;
  }

  @media (max-width: $screen-xs-min) {
    font-size: 22px;
  }
}