 @import '../../styles/base/variables.scss';

.imprintScreenContainer {
  width: 100%;
  height: 100%;

  h1 {
    text-align: center;
    margin: 30px 0;
  }

  h3 {
    color: $black;
    margin-bottom: 10px;
  }

  .container {
    background: $white;
    padding: 2em;
    margin-bottom: 2em;
  }
}
