@import '../../../styles/base/variables.scss';

.introVideoContainer {
  width: 100%;
  height: 100%;

  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .buttonsContainer {
    position: fixed;
    bottom: 10px;
    right: 10px;

    button {
      padding: 8px 10px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      background-color: rgba(0, 0, 0, 0.7);
      color: $white;
      outline: none;
      box-shadow: none;

      img {
        width: 100%;
        height: 100%;
      }

      svg {
        margin-top: -3px;
      }
    }

    .buttonMute {
      margin-right: 5px;
    }

    button:hover {
      background-color: rgba(100, 100, 100, 0.7);
      cursor: pointer;
    }
  }

}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
