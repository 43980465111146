@import '../../styles/base/variables.scss';

.interestScreenContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  
  .contentHeader {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    padding: 25px 0 0 0;
  }

  .bar {
    display: block;
    position: relative;
    width: 30.33%;
    max-width: 150px;
    height: 36vh;
    max-height: 400px;
    margin: auto;

    @media (max-width: $screen-sm-min) {
      max-width: 120px;
    }
  
    @media (max-width: $screen-xs-min) {
      max-width: 90px;
    }
  }
  
  .rankText {
    font-weight: 400;
    padding: 20px 15vw;
  }

  .playButtonLink {
    button {
      padding: 9px 8px 8px 10px !important;
      line-height: 0;
      margin-bottom: 15px;
    }
  }

  .section {
    padding: 20px 15px;

    h3 {
      color: $black;
      margin-bottom: 10px;
    }
  }

  .footnote {
    p {
      @media (max-width: $screen-xs-min) {
        padding: 5px 0;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .spacer {
    width: 100%;
    height: 8px;
    background-color: white;
  }
}