@import '../../styles/base/variables.scss';

$imageLength: 160px;
$imagePadding: 20px;

.favoriteProfessionsList {
  position: relative;
  width: 100%;
  background-color: $white;

  .listRoot {
    padding: $imagePadding calc((100% - #{$imageLength + $imagePadding}) / 2);

    .professionTile {
      width: $imageLength;
      height: $imageLength;
      margin: 0 calc(#{$imagePadding} / 2);
  
      img {
        width: $imageLength;
        height: $imageLength;
      }

      img:hover {
        cursor: pointer;
      }
    }
  }

  @media (max-width: $screen-sm-min) {
    .listRoot {
      padding: calc(#{$imagePadding} * 0.8) calc((100% - #{$imageLength * 0.8 + $imagePadding * 0.8}) / 2);
  
      .professionTile {
        width: $imageLength * 0.8;
        height: $imageLength * 0.8;
        margin: 0 calc((#{$imagePadding} * 0.8) / 2);
    
        img {
          width: $imageLength * 0.8;
          height: $imageLength * 0.8;
        }
      }
    }
  }

  @media (max-width: $screen-xs-min) {
    .listRoot {
      padding: ($imagePadding * 0.6) calc((100% - #{$imageLength * 0.6 + $imagePadding * 0.6}) / 2);
  
      .professionTile {
        width: $imageLength * 0.6;
        height: $imageLength * 0.6;
        margin: 0 calc((#{$imagePadding} * 0.6) / 2);
    
        img {
          width: $imageLength * 0.6;
          height: $imageLength * 0.6;
        }
      }
    }
  }
}

.noFavoritesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(#{$imageLength} + #{2 * $imagePadding});
  text-align: center;
  padding: 0 15px;

  @media (max-width: $screen-sm-min) {
    height: calc((#{$imageLength} + #{2 * $imagePadding}) * 0.8 );
  }

  @media (max-width: $screen-xs-min) {
    height: calc((#{$imageLength} + #{2 * $imagePadding}) * 0.65 );
  }
}

.previousSlideButton,
.previousSlideButton:active,
.previousSlideButton:focus,
.nextSlideButton,
.nextSlideButton:active,
.nextSlideButton:focus {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  transform: translateY(-50%);

  img {
    width: 50px;
    height: 50px;

    @media (max-width: $screen-sm-min) {
      width: 43px;
      height: 43px;
    }
  
    @media (max-width: $screen-xs-min) {
      width: 36px;
      height: 36px;
    }
  }
}

.previousSlideButton:hover,
.nextSlideButton:hover {
  cursor: pointer;
}

.previousSlideButton:disabled,
.nextSlideButton:disabled {
  opacity: 0.5;
}

.previousSlideButton:disabled:hover,
.nextSlideButton:disabled:hover {
  cursor: not-allowed;
}

.previousSlideButton {
  left: 5px;
}

.nextSlideButton {
  right: 5px;
}
