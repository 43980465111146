@import '../../../styles/base/variables.scss';

.favoriteJobExpectationsContainer {
  width: 100%;
  height: 100%;

  .favoriteJobExpectationsContent {
    width: 100%;
    height: 100%;
  }

  .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 25px 0 50px 0;

    h1 {
      color: $black;
      text-align: center;
    }

    h2 {
      margin-top: 10px;
      color: $black;
      text-align: center;
      font-weight: normal;
    }
  }

  .favoriteJobExpectationLines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }
}