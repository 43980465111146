@import '../../styles/base/variables.scss';

.menuContainer {
  background-color: $jopsy-red;
  border-radius: 7px;
  padding: 15px 40px;
  margin-left: 5px;

  @media (max-width: $screen-sm-min) {
      padding: 15px 30px;
  }

  @media (max-width: $screen-xs-min) {
      padding: 15px 20px;
  }

  .menuBody {
      display: block;

      a {
          text-decoration: none;
      }
  }

  .menuItem {
      color: $white;
      font-size: 22px;
      font-weight: bold;
      padding: 10px 0;
      text-decoration: none !important;

      @media (max-width: $screen-sm-min) {
          font-size: 20px;
          padding: 7.5px 0;
      }

      @media (max-width: $screen-xs-min) {
          font-size: 18px;
          padding: 5px 0;
      }

      &.active {
        text-decoration: underline !important;
      }
  }

  .menuItem:hover {
    cursor: pointer;
  }

  .triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 60px 40px;
      border-color: transparent transparent $jopsy-red transparent;
      right: 50%;
      top: -60px;
      position: absolute;
  }
}