@import '../../../../styles/base/variables.scss';

.modal {
  display: flex;
  margin: auto;
  width: 90%;
  min-width: 300px;
  max-width: 550px;

  .header {
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .tileContainer {
    width: 100%;
    margin: auto;

    .menuTileContainer {
      margin: auto;
      display: block;
      width: 100%;

      .menuTile {
        display: inline-flex;
        vertical-align: middle;
        min-width: 46%;
        width: 50px;
        height: 200px;
        margin: 10px 2%;
        padding: 10px;
        border-radius: 20px;

        .tileContentContainer {
          height: fit-content;
          overflow-wrap: break-word;
          margin: auto;
          text-align: center;
          max-width: 100%;
          color: $white;
        }
      }

      .menuTile:hover {
        cursor: pointer;
      }
    }
  }

  .closeButton {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 13px;
    right: 13px;
  }

  .closeButton:hover {
    cursor: pointer;
  }
}